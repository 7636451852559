import Swiper from 'swiper/bundle';
import {WOW} from 'wowjs';
// import fancybox from '@fancyapps/fancybox';
//import 'slick-carousel';

export default {
  init() {
    // JavaScript to be fired on all pages

    $(document).foundation();

    window.addEventListener('scroll', reveal);
    function reveal(){
      var reveals = document.querySelectorAll('.animate');
      for (var i = 0; i < reveals.length; i++){
        var win_height = window.innerHeight;
        var reveal_top = reveals[i].getBoundingClientRect().top;
        var reveal_point = 150;
        if (reveal_top < win_height - reveal_point) {
          reveals[i].classList.add('active');
        } //else {
          //reveals[i].classList.remove('active');
        //}
      }
  }

//fancybox
// $(document).ready(function() {
// $(".staff-grid .grid-box").fancybox({
//     'hideOnContentClick': false,
// });
// });

// $(document).ready(function() {
// 	$(".staff-grid .grid-box").fancybox();
// });

    /*Sticky Header*/
    $(window).scroll(function () {
        if ($(window).scrollTop() >= 50) {
          $('.cs_header').addClass('is-sticky');
          $('body').addClass('is-sticky');
        } else {
          $('.cs_header').removeClass('is-sticky');
          $('body').removeClass('is-sticky');
        }
    });
    $('p').each(function() {
      var $this = $(this);
      if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
        $this.remove();
    });
    $('p span').each(function() {
      var $this = $(this);
      if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
        $this.remove();
    });
    // destination grid list view
    $('.grid-filter').click(function () {
      $('.blog-latest-news').removeClass('listView');
      $('.blog-latest-news').addClass('gridView');
    });
    $('.list-filter').click(function () {
      $('.blog-latest-news').addClass('listView');
      $('.blog-latest-news').removeClass('gridView'); 
    });

    // table accordian
    $('.accordian-tab').on('click', '.accordion', function() {
      $('.accordion').not($(this)).removeClass('active'); 
      $(this).toggleClass('active').next().slideToggle();

      $('.panel').not($(this).next()).slideUp(300);          
      $(this).parent('.accordian-tab').siblings().find('.accordian').removeClass('active');
    });
    $('.menu .menu-item-has-children > a').append('<span class="arrow"></span>');
    $('.menu .menu-item-has-children > a span').on('click', function (e) {
      console.log('1');
      e.preventDefault();
      if($(this).parent().parent().hasClass( "sub_open" ))
        {
          $(this).parent().parent().removeClass('sub_open').find('> ul ').slideToggle();
        }else{
          $('.menu .menu-item-has-children.sub_open').removeClass('sub_open').find('> ul ').slideToggle();
          $(this).parent().parent().addClass('sub_open').find('> ul ').slideToggle();
        }
      /* $(this).parent().parent().toggleClass('open').find('> ul ').slideToggle(); */
      /* $(this).parent().parent().siblings().find('> ul').slideUp(); */
      /* $(this).parent().parent().siblings().removeClass('open'); */
    });
    
    $('[data-target]').on('click', function(){
      var id = $(this).attr('data-target');
      $('div#' + id).toggleClass('open');
    });
    $('.close-toggle').on('click', function(){
      $('#off-canvas-menu').toggleClass('open');
    });
    
    $('.staff-grid .grid-box').click(function (e) {
      $('.grid-box.reveal').toggleClass('open');
    });

    new WOW({
      offset: 200
    }).init();

    $('#goToTop').on("click",function(){  
      $("html, body").animate({ scrollTop: 0 }, 500);
      return false;
    });
    // add class on toggle resposnive
    $('.cw_toggle').click(function (e) {
      e.stopPropagation();
      $(this).toggleClass('open');
      $('.top-bar').toggleClass('open');
      $('html').toggleClass('top-bar-open');
    });

    $('.open-search').click(function (e) {
      e.stopPropagation();
      $('html').toggleClass('top-bar-open');
      $('.home-search-form').toggleClass('open');
    });
    $('.close-icon').click(function (e) {
      e.stopPropagation();
      $('html').toggleClass('top-bar-open');
      $('.home-search-form').toggleClass('open');
    });
    //  toogle class main header submenu in responsive 
    $('.desktop-menu .menu .menu-item').click(function () {
      $('.desktop-menu .menu .menu-item').not(this).removeClass('open');
      $(this).toggleClass('open');
    });
    $('.gform_button').wrap('<div class="bg"></div>');
    $('.gform_footer .bg').wrap('<div class="cs-btn green-btn"></div>');
    // lightGallery(document.getElementById('imageGallery'));
  

    $(function() {
      $('.card-wrapper').matchHeight();
    });
      //cscnl-job grid slider
  var swiper = new Swiper(".job", {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween:140,
    grid: {
      rows: 2,
      columns:2,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1, 
        slidesPerGroup: 1,
        rows: 1,
      },
      767: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        rows: 3,
      },
      1024: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        rows: 2,
      },
      1299: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  });
  
  var homeSwiper = new Swiper(".heroSwiper", {
    effect: "fade",
    paginationClickable: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
  
  var eventSwiper = new Swiper(".eventSwiper", {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        nextEl: ".event-next", 
        prevEl: ".event-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1499: {
          slidesPerView: 4,
        },
      },
  });


  
    var eventblogSwiper = new Swiper(".eventblogSwiper", {
      slidesPerView: 3,
      spaceBetween: 83,
      navigation: {
        nextEl: ".featured-event-next",
        prevEl: ".featured-event-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20, 
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 63,
        },
        1299: {
          slidesPerView: 3,
          spaceBetween: 83,
        },
      },
    });


    //jobs-slider 
    var jobSwiper = new Swiper(".jobs", {
      slidesPerGroup: 3, 
      spaceBetween:140,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1366: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
  
    var latestnews = new Swiper(".latestnews", {
      slidesPerView: 3,
      spaceBetween: 83,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20, 
          navigation: {
            nextEl: ".featured-event-next",
            prevEl: ".featured-event-prev",
          },
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 83,
        },
        1299: {
          slidesPerView: 3,
          spaceBetween: 103,
        },
      },
    });

      $('.timeLineSlider').slick({
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav, .slider-nav-1',
        // dots:true,
        // customPaging: function (slider, i) {
        //   return '<button class="tab">' + $(slider.$slides[i]).attr('data-dot') + '</button>';
        // },
      });
    
    $('.slider-nav').slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      asNavFor: '.timeLineSlider, .slider-nav-1',
      dots: false,
      focusOnSelect: true,
      responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 8,
          }
        },
        {
          breakpoint: 1008,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 3,
          },
        },
    
      ]
    });
    $('.slider-nav-1').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.timeLineSlider',
      dots: false,
      arrows: false,
      focusOnSelect: true,
    });

    var ajax_call =  ajax_object.ajaxurl;
  
        $('.year').on('change', function() {
          var select = $(this).find(":selected").val();
           $.post( ajax_call, {
            action: "annual_reports_data_fetch",
            select: select,
          }, function(data) {
            if(data){
              $('.report-grid').html(data);
            }else{
              $('.report-grid').html('<h3>No Data Found</h3>');
            }
          });

      });
         $('.sec-year').on('change', function() {
          var select = $(this).find(":selected").val();
           $.post( ajax_call, {
            action: "sec_reports_data_fetch",
            select: select,
          }, function(data) {
            if(data){
              $('.report-grid').html(data);
            }else{
              $('.report-grid').html('<h3>No Data Found</h3>');
            }
          });

      });
       $('#sec-search_val').on('input',function(e){
          var select =  $(this).val();
           var select_id = jQuery('#sec-cat-id').find(":selected").attr('data-name');           
          $.post( ajax_call, {
            action: "sec_reports_data_fetch",
            select: select,
            select_id: select_id,
          }, function(data) {
            if(data){
              $('.report-grid').html(data);
            }else{
              $('.report-grid').html('<h3>No Data Found</h3>');
            }
            
          });
        });   
        $('input.search_val').on('input',function(e){
          var select =  $(this).val();
          $.post( ajax_call, {
            action: "annual_reports_data_fetch",
            select: select,
          }, function(data) {
            if(data){
              $('.report-grid').html(data);
            }else{
              $('.report-grid').html('<h3>No Data Found</h3>');
            }
            
          });
        });
        jQuery('#events_cats').on('change', function() {
          jQuery('#search-form').val('');
          jQuery('#event_year').val('');
          var select = jQuery(this).find(":selected").val();
           jQuery.post( ajax_call, {
            action: "events_data_fetch",
            event_cat: select,
          }, function(data) {           
                     
            if(data){                       
              jQuery('#event-grid').html(data);          
              jQuery("#loadmoreall").show(); 
              jQuery(".event-list-box .box-md").slice(0,3).show();
              if(jQuery(".event-list-box .box-md").length <= 3) {
                  jQuery(".loadmoreall").hide();
              }  
            }else{              
                jQuery('#event-grid').html('<h3>Not Event found</h3>');
                jQuery("#loadmoreall").hide();  
            }
          });
      });
      jQuery('#event_year').on('change', function() {
         jQuery('#search-form').val('');
          var year_select = jQuery(this).find(":selected").val(); 
           var select = jQuery('#events_cats').find(":selected").val();        
           jQuery.post( ajax_call, {
            action: "events_year_data_fetch",
            year_select: year_select,
            event_cat: select,
          }, function(data) {      
            if(data){
              jQuery('#event-grid').html(data);
              jQuery("#loadmoreall").show(); 
              jQuery(".event-list-box .box-md").slice(0,3).show();
              if(jQuery(".event-list-box .box-md").length <= 3) {
                  jQuery(".loadmoreall").hide();
              }              
            }else{              
                 jQuery('#event-grid').html('<h3>Not Event found</h3>');
                 jQuery("#loadmoreall").hide();  
            }
          });
      });
        
      jQuery('input#search-form').on('input',function(e){          
        var select = jQuery('#events_cats').find(":selected").val();
        var search_val = jQuery(this).val();
        var event_year = jQuery('#event_year').find(":selected").val();
         jQuery.post( ajax_call, {
          action: "search_events_data_fetch",
          event_cat: select,
          search_val: search_val,
          event_year: event_year,
        }, function(data) {       
          if(data){
          jQuery('#event-grid').html(data);
          jQuery("#loadmoreall").show(); 
          jQuery(".event-list-box .box-md").slice(0,3).show();
            if(jQuery(".event-list-box .box-md").length <= 3) {
                jQuery(".loadmoreall").hide();
            }             
          }else{
               jQuery('#event-grid').html('<h3>Not Event found</h3>');
               jQuery("#loadmoreall").hide();  
          }
        });

      });
      jQuery(".event-list-box .box-md").slice(0,3).show();
      if(jQuery(".event-list-box .box-md").length <= 3) {
          jQuery(".loadmoreall").hide();
      }
      jQuery(".loadmoreall").click(function(e){
          e.preventDefault();
          jQuery(".event-list-box .box-md:hidden").slice(0,3).fadeIn("slow");
          if(jQuery(".event-list-box .box-md:hidden").length == 0){
              jQuery(".loadmoreall").hide();                               
          }
      });
      jQuery('#our_work_cats').on('change', function() {
        jQuery('#search-forms').val('');
        jQuery('#our_work_year').val('');
        var our_work_select = jQuery(this).find(":selected").val();
         jQuery.post( ajax_call, {
          action: "our_work_data_fetch",
          our_work_cat: our_work_select,
        }, function(data) {           
                   
          if(data){                       
            jQuery('#our_work-list-box').html(data);          
            jQuery("#loadmore-all").show(); 
            jQuery(".our_work-list-box .box-md").slice(0,6).show();
            if(jQuery(".our_work-list-box .box-md").length <= 6) {
                jQuery(".loadmore-all").hide();
            }  
          }else{              
              jQuery('#our_work-list-box').html('<h3>Not Our Works found</h3>');
              jQuery("#loadmore-all").hide();  
          }
        });
      });
      jQuery('#our_work_year').on('change', function() {
         jQuery('#search-forms').val('');
          var work_our_year_select = jQuery(this).find(":selected").val(); 
           var our_work_cat = jQuery('#our_work_cats').find(":selected").val();        
           jQuery.post( ajax_call, {
            action: "our_work_year_data_fetch",
            work_our_year_select: work_our_year_select,
            our_work_cat: our_work_cat,
          }, function(data) {      
            if(data){
              jQuery('#our_work-list-box').html(data);
              jQuery("#loadmore-all").show(); 
              jQuery(".our_work-list-box .box-md").slice(0,6).show();
              if(jQuery(".our_work-list-box .box-md").length <= 6) {
                  jQuery(".loadmore-all").hide();
              }              
            }else{              
                 jQuery('#our_work-list-box').html('<h3>Not Our Works found</h3>');
                 jQuery("#loadmore-all").hide();  
            }
          });
      });        
      jQuery('input#search-forms').on('input',function(e){          
        var our_work_cat = jQuery('#our_work_cats').find(":selected").val();
        var search_val = jQuery(this).val();
        var work_our_year_select = jQuery('#our_work_year').find(":selected").val();
         jQuery.post( ajax_call, {
          action: "search_our_work_data_fetch",
          our_work_cat: our_work_cat,
          search_val: search_val,
          work_our_year_select: work_our_year_select,
        }, function(data) {       
          if(data){
          jQuery('#our_work-list-box').html(data);
          jQuery("#loadmore-all").show(); 
          jQuery(".our_work-list-box .box-md").slice(0,6).show();
            if(jQuery(".our_work-list-box .box-md").length <= 6) {
                jQuery(".loadmore-all").hide();
            }             
          }else{
               jQuery('#our_work-list-box').html('<h3>Not Our Works found</h3>');
               jQuery("#loadmore-all").hide();  
          }
        });

      });
    jQuery(".our_work-list-box .box-md").slice(0,6).show();
    if(jQuery(".our_work-list-box .box-md").length <= 6) {
        jQuery(".loadmore-all").hide();
    }
    jQuery(".loadmore-all").click(function(e){
        e.preventDefault();
        jQuery(".our_work-list-box .box-md:hidden").slice(0,3).fadeIn("slow");
        if(jQuery(".our_work-list-box .box-md:hidden").length == 0){
            jQuery(".loadmore-all").hide();                               
        }
    });
    jQuery("#bloglist-grid .box-md").slice(0,6).show();
    if(jQuery("#bloglist-grid .box-md").length <= 6) {
        jQuery("#blog-all-loadmore").hide();
    }
    jQuery(".blog-all-loadmore").click(function(e){
        e.preventDefault();
        jQuery("#bloglist-grid .box-md:hidden").slice(0,3).fadeIn("slow");
        if(jQuery("#bloglist-grid .box-md:hidden").length == 0){
            jQuery("#blog-all-loadmore").hide();                               
        }
    });
    

    jQuery('#post_cats').on('change', function() {
     
        jQuery('#post-searchfrm').val('');
        jQuery('#post_year').val('');
        var post_cat_select = jQuery(this).find(":selected").val();
         jQuery.post( ajax_call, {
          action: "all_post_data_fetch",
          post_cat_select: post_cat_select,
        }, function(data) {                  
          if(data){                       
            jQuery('#bloglist-grid').html(data);          
            jQuery("#blog-all-loadmore").show(); 
            jQuery(".bloglist-grid .box-md").slice(0,6).show();
            if(jQuery(".bloglist-grid .box-md").length <= 6) {
                jQuery(".blog-all-loadmore").hide();
            }  
          }else{              
              jQuery('#bloglist-grid').html('<h3>Not Post found</h3>');
              jQuery("#blog-all-loadmore").hide();  
          }
        });
      });
      jQuery('#post_year').on('change', function() {
         jQuery('#post-searchfrm').val('');
          var post_year = jQuery(this).find(":selected").val(); 
           var post_cat_select = jQuery('#post_cats').find(":selected").val();        
           jQuery.post( ajax_call, {
            action: "post_year_data_fetch",
            post_year: post_year,
            post_cat_select: post_cat_select,
          }, function(data) {      
            if(data){
              jQuery('#bloglist-grid').html(data);
              jQuery("#blog-all-loadmore").show(); 
              jQuery(".bloglist-grid .box-md").slice(0,6).show();
              if(jQuery(".bloglist-grid .box-md").length <= 6) {
                  jQuery(".blog-all-loadmore").hide();
              }              
            }else{              
                 jQuery('#bloglist-grid').html('<h3>Not Post found</h3>');
                 jQuery("#blog-all-loadmore").hide();  
            }
          });
      }); 
      jQuery('input#post-searchfrm').on('input',function(e){          
        var post_cat_select = jQuery('#post_cats').find(":selected").val();
        var search_val = jQuery(this).val();
        var post_year = jQuery('#post_year').find(":selected").val();
         jQuery.post( ajax_call, {
          action: "search_post_data_fetch",
          post_cat_select: post_cat_select,
          search_val: search_val,
          post_year: post_year,
        }, function(data) {       
          if(data){
          jQuery('#bloglist-grid').html(data);
          jQuery("#blog-all-loadmore").show(); 
          jQuery(".bloglist-grid .box-md").slice(0,6).show();
            if(jQuery(".bloglist-grid .box-md").length <= 6) {
                jQuery(".blog-all-loadmore").hide();
            }             
          }else{
               jQuery('#bloglist-grid').html('<h3>Not Post found</h3>');
               jQuery("#blog-all-loadmore").hide();  
          }
        });

      });  
      jQuery('#resources_cats').on('change', function() {
          jQuery('#resources-frm-cat').val('');        
          var res_cat_select = jQuery(this).find(":selected").val();
           jQuery.post( ajax_call, {
            action: "resource_cat_data_fetch",
            res_cat_select: res_cat_select,
          }, function(data) { 
            if(data){                       
              jQuery('#resources-gride').html(data);
            }else{              
                jQuery('#resources-gride').html('<h3>Not Resources found</h3>');
                 
            }
          });
      }); 
      jQuery('input#resources-frm-cat').on('input',function(e){          
        var search_val = jQuery(this).val();       
        jQuery('#resources_cats').val('');
         jQuery.post( ajax_call, {
          action: "resource_search_cat_data_fetch",         
          search_val: search_val,         
        }, function(data) {       
          if(data){
          jQuery('#resources-gride').html(data);         
                        
          }else{
               jQuery('#resources-gride').html('<h3>Not Resources found</h3>');
             
          }
        });

      });  
        $('#resource_sub_cat').on('change', function() {
          $('#resource_single').val('');
          var select_cat_id = $(this).find(":selected").val();
          var select_data_id = $(this).find(":selected").data('id');
          var select_data_name = $(this).find(":selected").data('name');
           $.post( ajax_call, {
            action: "resources_categories_data_fetch",
            select_cat_id: select_cat_id,
            select_data_id: select_data_id,
            select_data_name: select_data_name,
          }, function(data) {
            if(data){
            $('.category-sec').html(data);
            }else{
               $('.category-sec').html('<h3>No Data Found</h3>');
            }
          });
      });
      jQuery('input#resource_single').on('input',function(e){
          var resource_search_val = jQuery(this).val();
          var select_cat_id = $('#resource_sub_cat').find(":selected").val();
          var select_data_id = $(this).data('id');
           $.post( ajax_call, {
            action: "resources_signle_search_data_fetch",
            resource_search_val: resource_search_val,
            select_cat_id: select_cat_id,
            select_data_id: select_data_id,
          }, function(data) {
            if(data){
            $('.category-sec').html(data);
            }else{
               $('.category-sec').html('<h3>No Data Found</h3>');
            }
          });
      });
      $('#directorys_cat').on('change', function() {
          $('#directorys_search').val('');
          var direct_cat_id = $(this).find(":selected").val();
           $.post( ajax_call, {
            action: "directory_categories_data_fetch",
            direct_cat_id: direct_cat_id,
          }, function(data) {
            if(data){
            $('.accordian-table').html(data);
            }else{
               $('.accordian-table').html('<h3>No Data Found</h3>');
            }
          });
      });

     $('#directorys_year').on('change', function() {
          var directorys_year = $(this).find(":selected").val();
          var direct_cat_id = $('#directorys_cat').find(":selected").val();
           $.post( ajax_call, {
            action: "directory_categories_data_fetch",
            directorys_year: directorys_year,
            direct_cat_id: direct_cat_id,
          }, function(data) {
            if(data){
            $('.accordian-table').html(data);
            }else{
               $('.accordian-table').html('<h3>No Data Found</h3>');
            }
          });
      });

      jQuery('input#directorys_search').on('input',function(e){
          var directorys_search_val = jQuery(this).val();
          var select_cat_id = $('#directorys_cat').find(":selected").val();
          var directorys_year = $('#directorys_year').find(":selected").val();
           $.post( ajax_call, {
            action: "directory_search_data_fetch",
            directorys_search_val: directorys_search_val,
            select_cat_id: select_cat_id,
            directorys_year: directorys_year,
          }, function(data) {
            if(data){
            $('.accordian-table').html(data);
            }else{
               $('.accordian-table').html('<h3>No Data Found</h3>');
            }
          });
      });

     $('#alphabet a').on('click', function() {
          $('#directorys_search').val('');
          var alphabet_id = $(this).data('id');
          var select_cat_id = $('#directorys_cat').find(":selected").val();
          var directorys_year = $('#directorys_year').find(":selected").val();
          $('#alphabet a').removeClass('active');
          $(this).addClass('active');
           $.post( ajax_call, {
            action: "directory_search_data_fetch",
            directorys_search_val: alphabet_id,
            select_cat_id: select_cat_id,
            directorys_year: directorys_year,
          }, function(data) {
            if(data){
            $('.accordian-table').html(data);
            }else{
               $('.accordian-table').html('<h3>No Data Found</h3>');
            }
          });
      });

         

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
